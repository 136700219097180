export const SIGN_UP_COMPLETED = "project/users/signUpCompleted"
export const LOGIN_COMPLETED = "project/users/loginCompleted"
export const LOGOUT = "project/users/logout"
export const CLEAR_NOTES = "project/notes/clear"
export const GET_NOTES_RESULT = "project/notes/get"
export const SELECT_NOTE = "project/notes/select"
export const SAVE_NOTE = "project/notes/save"
export const EXIT_NOTE = "project/notes/exit"
export const NEW_NOTE = "project/notes/new"
export const CHANGE_SORTING = "project/notes/sorting"
export const CHANGE_FILTER = "project/notes/filter"