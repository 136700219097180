const getUsertate = state => state.user

const sortings = [
    (a,b) => {
        if(a.title < b.title) { return -1 }
        if(a.title > b.title) { return 1 }
        return 0
    },
    (a,b) => {
        if(a.title < b.title) { return 1 }
        if(a.title > b.title) { return -1 }
        return 0
    },
    (a,b) => {
        if(a.creationDate.nanoseconds < b.creationDate.nanoseconds) { return -1 }
        if(a.creationDate.nanoseconds > b.creationDate.nanoseconds) { return 1 }
        return 0
    },
    (a,b) => {
        if(a.creationDate.nanoseconds < b.creationDate.nanoseconds) { return 1 }
        if(a.creationDate.nanoseconds > b.creationDate.nanoseconds) { return -1 }
        return 0
    },
    (a,b) => {
        if(a.lastUpdate.nanoseconds < b.lastUpdate.nanoseconds) { return -1 }
        if(a.lastUpdate.nanoseconds > b.lastUpdate.nanoseconds) { return 1 }
        return 0
    },
    (a,b) => {
        if(a.lastUpdate.nanoseconds < b.lastUpdate.nanoseconds) { return 1 }
        if(a.lastUpdate.nanoseconds > b.lastUpdate.nanoseconds) { return -1 }
        return 0;
    }
]

export const getUser = state =>
    getUsertate(state).user

export const isLoggedIn = state =>
    getUser(state) !== null

export const getNotes = state => {
    let notes = getUsertate(state).notes
    const sorting = getSorting(state)
    const filter = getFilter(state)
    let filteredNotes

    if (notes == null) return []

    if (filter !== '')
        filteredNotes = notes.filter(n => n.title.toLowerCase().includes(filter.toLowerCase()))
    else 
        filteredNotes = notes

    return filteredNotes.sort(sortings[sorting])
}
    

export const getSelectedNote = state =>
    getUsertate(state).selected

export const getSelectedNoteLastUpdatedSeconds = state =>
    getSelectedNote(state).lastUpdate.seconds

const getFilterOptions = state =>
    getUsertate(state).filterOptions

export const getSorting = state =>
    getFilterOptions(state).sorting

export const getFilter = state =>
    getFilterOptions(state).filter