import { initializeApp } from "firebase/app"
import { collection, doc, getDoc, getDocs, getFirestore, setDoc, addDoc, deleteDoc } from "firebase/firestore"
import {
  GoogleAuthProvider,
  GithubAuthProvider,
  getAuth,
  signOut,
  signInWithPopup,
  browserLocalPersistence
} from "firebase/auth"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

const googleProvider = new GoogleAuthProvider()
const githubProvider = new GithubAuthProvider().addScope('read:user')

const checkFirestore = async () => {
    const docRef = doc(db, 'users', auth.currentUser.uid)
    const userDoc = await getDoc(docRef)
    if (!userDoc.exists()) {
        setDoc(docRef,{notes:[]})
    }
}

const loginWithGoogle = async (onSuccess, onErrors) => {
    try {
        const res = await signInWithPopup(auth, googleProvider)
        checkFirestore()
        onSuccess(res.user)
    } catch (err) {
        onErrors(err)
    }
}

const loginWithGithub = async (onSuccess, onErrors) => {
    try {
        const res = await signInWithPopup(auth, githubProvider)
        checkFirestore()
        onSuccess(res.user)
    } catch (err) {
        onErrors(err)
    }
}


const loginFromPersistance = (onSuccess, onErrors) => {
    try {

        auth.setPersistence(browserLocalPersistence).then(() => {
            if (auth.currentUser) {
                onSuccess(auth.currentUser)
            }
        })     

    } catch (err) {
        onErrors(err)
    }
}

const getNotes = async (onSuccess, onErrors) => {
    try {

        let notes = []

        const collectionRef = collection(db, 'users', auth.currentUser.uid, 'notes')
        const notesSnap = await getDocs(collectionRef)

        notesSnap.forEach(doc => notes.push({id:doc.id, ...doc.data()}))

        onSuccess(notes)

    } catch (err) {
        onErrors(err)
    }
}

const updateNote = async (note, onSuccess, onErrors) => {
    try {
        const noteRef = doc(db, 'users', auth.currentUser.uid, 'notes', note.id)

        await setDoc(noteRef,{
            title: note.title,
            body: note.body,
            creationDate: note.creationDate,
            lastUpdate: note.lastUpdate
        })

        onSuccess()

    } catch (err) {
        onErrors(err)
    }
}

const newNote = async (note, onSuccess, onErrors) => {
    try {
        const notesRef = collection(db,'users', auth.currentUser.uid, 'notes')
        await addDoc(notesRef, note)
        onSuccess()
    } catch (err) {
        console.log(err)
        onErrors(err)
    }
}

const removeNote = async (id, onSuccess, onErrors) => {
    try {
        const noteRef = doc(db,'users', auth.currentUser.uid, 'notes', id)
        await deleteDoc(noteRef)
        onSuccess()
    } catch(err) {
        console.log(err)
        onErrors(err)
    }

}

const logout = () => {
    signOut(auth)
}

export {
    auth,
    loginWithGoogle,
    loginWithGithub,
    loginFromPersistance,
    logout,
    getNotes,
    updateNote,
    newNote,
    removeNote,
    db
}