import React from 'react'
import { 
    Box, 
    Stack, 
    Button, 
    Container, 
    Heading,  
    Text, 
    useToast
} from '@chakra-ui/react'
import { FaGoogle, FaGithub } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { getNotes, loginWithGoogle, loginWithGithub } from '../actions'

const loginErrorToastConfig = {
    title: 'Login Error', 
    description: 'This account is used with another provider', 
    status: 'error', 
    duration: 5000, 
    isClosable: true
}

const Login = () => {
    const dispatch = useDispatch()
    const toast = useToast()

    const handleGoogleLogin = () => {
        dispatch(loginWithGoogle(
            () => dispatch(getNotes()),
            (error) => {
                if (error.code === 'auth/account-exists-with-different-credential')
                    return toast(loginErrorToastConfig)
                console.log(error.code)
            }
        ))
    }

    const handleGitHubLogin = () => {
        dispatch(loginWithGithub(
            () => dispatch(getNotes()),
            (error) => {
                if (error.code === 'auth/account-exists-with-different-credential')
                    return toast(loginErrorToastConfig)
                console.log(error.code)
            }
        ))
    }
    
    return (
        <Container mt='10'>
            <Heading mb={5} textAlign='center'>
                Login
            </Heading> 
            <Box>
                <Stack spacing={5}>
                    <Button onClick={handleGoogleLogin}>
                        <FaGoogle/>
                        <Text ml={2}>
                            with Google
                        </Text>
                    </Button>
                    <Button onClick={handleGitHubLogin}>
                        <FaGithub/>
                        <Text ml={2}>
                            with GitHub
                        </Text>
                    </Button>
                </Stack>
            </Box>
        </Container>
        
    )

}

export default Login