import { combineReducers } from 'redux'
import * as actionTypes from '../actionsTypes'

const initialState = {
    user : null,
    notes: null,
    selected: null,
    filterOptions: {filter: '', sorting: 0}
}

const user = (state = initialState.user, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_COMPLETED:
            return action.authenticatedUser
        case actionTypes.LOGOUT:
            return initialState.user
        default:
            return state
    }
}

const notes = (state = initialState.notes, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_NOTES:
            return initialState.notes
        case actionTypes.GET_NOTES_RESULT:
            return action.notes
        default:
            return state
    }
}

const selected  = (state = initialState.selected, action) => {
    switch (action.type) {
        case actionTypes.SELECT_NOTE:
            return action.note
        case actionTypes.EXIT_NOTE:
            return initialState.selected
        default:
            return state
    }
}

const filterOptions = (state = initialState.filterOptions, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_SORTING:
            return  {filter: state.filter, sorting: action.sorting}
        case actionTypes.CHANGE_FILTER:
            return  {filter: action.filter, sorting: state.sorting}
        default:
            return state
    }
}

const UserReducer = combineReducers({user, notes, selected, filterOptions})

export default UserReducer