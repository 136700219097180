import { Heading, Text, Flex } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { selectNote } from '../actions'

const hour = 60*60
const day = hour*24
const week = day*7
const month = day*30
const year = month*12

const Note = (props) => {

    const dispatch = useDispatch()
    const note = props.note
    const { title, body, lastUpdate } = note
    const parsedBody = body.replaceAll('\\n','\n')
    const firstLine = parsedBody.split('\n')[0]
    

    const secondsToDate = (seconds) => {
        const now = new Date().getTime() / 1000
        const timeInterval = now - seconds
        let string = 'many time'

        if (timeInterval < hour) {
            string = 'last hour'
        } else if (timeInterval < day) {
            string = 'last day'
        } else if (timeInterval < week) {
            string = 'last week'
        } else if (timeInterval < month) {
            string = 'last month'
        } else if (timeInterval < year) {
            string = 'last year'
        }

        string += ' - '+ new Date(seconds*1000).toLocaleDateString()

        return string
    }

    const handleClick = () => {
        dispatch(selectNote(note))
    }

    return (
        <Flex 
            direction='row'  
            p='3' 
            bg='blackAlpha.400' 
            onClick={() => handleClick()}
            onKeyDown={(ev) => {if (ev.key === "Enter") handleClick()}}
            borderRadius='15px'
            _hover={{ background: 'blue.700' }}
            _focus={{ background: 'blue.700' }}
            cursor='pointer'
            tabIndex='0'
        >
            <Flex direction='column' justifyContent='space-evenly' w="100%">
                <Heading size='lg' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>{title}</Heading>
                <Text overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>{firstLine}</Text>
                <Text color='#888'>{secondsToDate(lastUpdate.seconds)}</Text>
            </Flex>
        </Flex>
    )
}

export default Note