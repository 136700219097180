import { Box, Flex, Avatar, Menu, MenuButton, MenuList, MenuItem, IconButton, Spacer, Input, InputGroup, InputLeftElement, Divider, Button } from "@chakra-ui/react"
import { HamburgerIcon, SearchIcon, AddIcon, ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { BiLogOut } from 'react-icons/bi'
import { FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa'
import Note from './Note'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, getNotes, getSorting, getFilter } from '../selectors'
import { logout, newNote, changeSorting, changeFilter } from '../actions'

const sortings = [
    {icon: <FaSortAlphaDown />, text: 'Title'},
    {icon: <FaSortAlphaDownAlt />, text: 'Title'},
    {icon: <ArrowDownIcon />, text: 'Creation'},
    {icon: <ArrowUpIcon />, text: 'Creation'},
    {icon: <ArrowDownIcon />, text: 'Update'},
    {icon: <ArrowUpIcon />, text: 'Update'},
]

const SideSection = (props) => {

    const user = useSelector(getUser)
    const notes = useSelector(getNotes)
    const sorting = useSelector(getSorting)
    const filter = useSelector(getFilter)
    const dispatch = useDispatch()

    const handleLogout = () => dispatch(logout())
    const handleNewNote = () => {
        dispatch(newNote())
    }

    if (!user) return <Box>Cargando</Box>

    return (
        <Flex {...props} direction='column'>
            <Flex alignItems='center' w='full' bg='dark.400' h='60px' px={3}>
                <Avatar name={user.displayName} src={user.photoURL} my='1' />
                <Spacer /> 
                <Menu>
                    <MenuButton 
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon />}
                        variant='outline'
                    />
                    <MenuList>
                        <MenuItem icon={<BiLogOut />} onClick={handleLogout}>
                            LogOut
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
            <Flex
                bg='whiteAlpha.50'
                h='50px'
                alignItems='center'
                px={3}
                gap='1'
            >
                <InputGroup size='sm'>
                    <Input 
                        size='sm'
                        placeholder='Search by title'
                        value={filter}
                        onChange={(e) => dispatch(changeFilter(e.target.value))}
                    />
                    <InputLeftElement>
                        <IconButton size='xs' icon={<SearchIcon/>}/>
                    </InputLeftElement>
                </InputGroup>
                <Menu>
                    <MenuButton 
                        as={Button}
                        aria-label='Options'
                        leftIcon={sortings[sorting].icon}
                        variant='outline'
                        size='sm'
                        w='120px'
                    >
                        {sortings[sorting].text}
                    </MenuButton>
                    <MenuList>
                        {sortings.map((v,i) => <MenuItem key={i} icon={v.icon} onClick={() => dispatch(changeSorting(i))}>{v.text}</MenuItem>)}
                    </MenuList>
                </Menu>
                
            </Flex>
            <Divider/>
            <Flex
                direction='column'
                bg='whiteAlpha.50'
                h='full' 
                px='3'
                py='2' 
                gap='2' 
                overflowY='scroll'>
                {notes && notes.map(note => 
                    <Note key={note.id} note={note}/>
                )
                }    
            </Flex>
            <IconButton my='1' icon={<AddIcon/>} onClick={handleNewNote}/>
        </Flex>
    )

}

export default SideSection