import { extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

const styles = {
    global: props => ({
        body: {
            bg: mode('background.light', 'background.dark')(props)
        }
    })
}

const components = {
    Link: {
        baseStyle: props => ({
            color: mode("customBlue.dark","customBlue.light")(props),
            textUnderlineOffset: 5
        }),
    }
}

const fonts = {
    
}

const colors = {
    background : {
        light: '#e0e0e0',
        dark: '#141414'
    },
    customBlue : {
        light :'#86bbd8',
        dark: '#32769A'
    },
    dark: {
        400: '#292929',
        base: '#141414'
    }
}

const config = {
    initialColorMode: 'dark',
    useSystemColorMode: 'true'
}

const theme = extendTheme({
    config,
    styles,
    components,
    colors,
    fonts
})

export default theme