import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react'
import { useRef } from 'react'
import PropTypes from 'prop-types'

const CustomDialog = ({header, body, isOpen, onClose, onAction, closeText, actionText}) => {

    const cancelRef = useRef(null)

    return (
        <AlertDialog isOpen={isOpen}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontWeight='bold'>
                        {header}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {body}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                        {closeText}
                    </Button>
                    <Button backgroundColor='red.500' onClick={() => {onAction(); onClose()}} ml={3}>
                        {actionText}
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
            
        </AlertDialog>
    )

}

CustomDialog.propTypes = {
    header: PropTypes.string,
    body: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onAction: PropTypes.func,
    closeText: PropTypes.string,
    actionText: PropTypes.string
}

export default CustomDialog