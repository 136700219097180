import { Input, Textarea, Flex, IconButton, useDisclosure, useToast, Text } from '@chakra-ui/react'
import { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CloseIcon, DeleteIcon, DownloadIcon } from '@chakra-ui/icons'
import { FaSave } from 'react-icons/fa'
import { MD5 } from 'crypto-js'
import { getSelectedNote, getSelectedNoteLastUpdatedSeconds } from '../selectors'
import { exitNote, saveNote, removeNote } from '../actions'
import CustomDialog from './CustomDialog'
import { useRef } from 'react'


const parseText = (text) => text.replaceAll('\\n','\n')


const WorkArea = (props) => {

    const selectedNote = useSelector(getSelectedNote, (n1,n2) => n1.id === n2.id)
    const lastUpdateSeconds = useSelector(getSelectedNoteLastUpdatedSeconds, (s1, s2) => s1 === s2)
    const dispatch = useDispatch()
    const { isOpen: isOpenCloseDialog, onOpen: openCloseDialog, onClose: onCloseCloseDialog } = useDisclosure()
    const { isOpen: isOpenDeleteDialog, onOpen: openDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure()
    const toast = useToast()
    const inputTitle = useRef()

    const [title, setTitle] = useState(selectedNote.title)
    const [body, setBody] = useState(parseText(selectedNote.body))
    const [updated, setUpdated] = useState(false)
    const [hash, setHash] = useState(null)
    const [currentHash, setCurrentHash] = useState(null)

    const handleSave = useCallback(() => {
        
        dispatch(
            saveNote(
                {id:selectedNote.id, title,body,creationDate: selectedNote.creationDate, lastUpdate: new Date()},
                () => {
                    setUpdated(false)
                    setHash(MD5(title+body).toString())
                    toast({
                    title: 'Save succesfully',
                    description: "Note saved successfully.",
                    position: 'bottom-right',
                    status: 'success',
                    duration: 1000,
                    isClosable: true,
                  })
                },
                () =>
                    toast({
                        title: 'Error on save',
                        description: "There was a problem saving the note.",
                        position: 'bottom-right',
                        status: 'error',
                        duration: 2500,
                        isClosable: true,
                      })
     
            )
        )

    },[body, dispatch, selectedNote.creationDate, selectedNote.id, title, toast])

    const handleExit = () => {
        if (updated) {
            openCloseDialog()
        } else {
            dispatch(exitNote())
        }  
    }

    const handleKeyPress = useCallback((event) => {
        const { repeat, ctrlKey, shiftKey, key } = event
        const keys = ['S', 'D', 'Q']

        if (repeat) return

        if (keys.includes(key)) event.preventDefault()

        if (ctrlKey && shiftKey && key === 'S') {
            if (updated) handleSave()
        }
        if (ctrlKey && shiftKey && key === 'D') {
            openDeleteDialog()
        }
        if (ctrlKey && shiftKey && key === 'Q') {
            handleExit()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleSave, handleExit, updated])

    useEffect(() => {
        const hash = MD5(selectedNote.title+selectedNote.body).toString()
        setUpdated(false)
        setTitle(selectedNote.title)
        setBody(selectedNote.body)
        setHash(hash)
        setCurrentHash(hash)
        inputTitle.current.focus()
    }, [selectedNote])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress)
        return () => document.removeEventListener('keydown', handleKeyPress)
    }, [handleKeyPress])

    useEffect(() => {
        setCurrentHash(MD5(title+body).toString())
    }, [title, body])

    useEffect(() => {
        if (currentHash !== hash)
            setUpdated(true)
        else
            setUpdated(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentHash])

    const handleChangleTitle = (e) => {
        setTitle(e.target.value)
    }

    const handleChangeBody = (e) => {
        setBody(e.target.value)
    }

    const handleDonwload = async () => {
        let a = document.createElement('a')
        a.href = window.URL.createObjectURL(new Blob([body], {type: 'text/plain'}))
        a.download = `${title}.txt`
        a.click()
    }

    const secondsToDateString = (seconds) => {
        const date = new Date(seconds*1000)
        return date.toLocaleString()
    }

    return (
        <Flex {...props} direction='column'>
            <Flex gap='2'>
                <IconButton icon={<FaSave/>} disabled={!updated} backgroundColor={(updated ? 'yellow.500' : '')} onClick={handleSave}/>
                <IconButton icon={<DownloadIcon/>} onClick={handleDonwload}/>
                <IconButton icon={<DeleteIcon/>} _hover={{backgroundColor: 'red.600'}} onClick={openDeleteDialog}/>
                <IconButton icon={<CloseIcon/>} ml='auto' onClick={handleExit} _hover={{backgroundColor: 'red.600'}}/>
                <CustomDialog
                    isOpen={isOpenCloseDialog}
                    header='Close note'
                    body='Are you sure? Progress that is not saved will be lost.'
                    closeText='Cancel' onClose={onCloseCloseDialog}
                    actionText='Close Note' onAction={() => dispatch(exitNote())}
                />
                <CustomDialog
                    isOpen={isOpenDeleteDialog}
                    header='Delete note'
                    body='Are you sure? Note will be lost.'
                    closeText='Cancel' onClose={onCloseDeleteDialog}
                    actionText='Delete' onAction={() => dispatch(removeNote(selectedNote.id))}
                />
            </Flex>
            <Input ref={inputTitle} value={title} onChange={handleChangleTitle} my='2'/>
            <Textarea value={body} onChange={handleChangeBody} h='full' resize='none'/>
            <Flex p={1} gap='2'>
                {/* <Text>Updated: {secondsToDateString(lastUpdateSeconds)}</Text> */}
                <Text ml='auto'>Creation: {secondsToDateString(selectedNote.creationDate.seconds)}</Text>
            </Flex>
        </Flex>
    )
    
}

export default WorkArea