import { Center, Heading, Text, Flex, Divider, List, ListItem, Kbd } from "@chakra-ui/react"

const Wellcome = (props) => (
    <Center  {...props}>
        <Flex direction='column' alignItems='center'>
            <Heading>Wellcome to Notes</Heading>
            <Text>An application to take and organize notes in a simple way</Text>
            <Divider my={2}/>
            <Heading>Shortcuts</Heading>
            <List m={1}>
                <ListItem>
                    <Kbd>Ctrl</Kbd>+<Kbd>Shift</Kbd>+<Kbd>S</Kbd> to Save Note
                </ListItem>
                <ListItem>
                    <Kbd>Ctrl</Kbd>+<Kbd>Shift</Kbd>+<Kbd>D</Kbd> to Delete Note
                </ListItem>
                <ListItem>
                    <Kbd>Ctrl</Kbd>+<Kbd>Shift</Kbd>+<Kbd>Q</Kbd> to Close Note
                </ListItem>
            </List>
        </Flex>
    </Center >
)

export default Wellcome