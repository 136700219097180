import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../selectors'
import theme from '../utils/theme'
import Main from './Main'
import Login from './Login'
import Register from './Register'
import { getNotes, loginFromPersistance } from '../actions'



const App = () => {

  const user = useSelector(getUser)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loginFromPersistance(
        () => dispatch(getNotes()),
        (error) => alert(error.message)
    ))
}, [dispatch])

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          {user && <Route exact path="/" element={<Main />} /> }
          {!user && <Route exect path="/" element={<Login />} /> }
          {!user && <Route exact path="/register" element={<Register />} /> }
        </Routes>
      </Router>
    </ChakraProvider>
  )
}

export default App
