import React from 'react'
import SideSection from './SideSection'
import WorkArea from './WorkArea'
import { Flex } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { getSelectedNote } from '../selectors'
import Wellcome from './Wellcome'

const Main = () => {

    const selectedNote = useSelector(getSelectedNote)
  
    return (
        <Flex>
            <SideSection w='25%' minW='300px' h='100vh'/>
            {selectedNote && <WorkArea w='75%' h='100vh' p='2' />}
            {!selectedNote && <Wellcome w='75%' h='100vh' p='2' />}  
        </Flex>
    )

}

export default Main