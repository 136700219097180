import * as firebase from '../utils/firebase'
import * as actionTypes from '../actionsTypes'

// const signUpCompleted = authenticatedUser => ({
//     type: actionTypes.SIGN_UP_COMPLETED,
//     authenticatedUser
// })


// export const signUp = (user, onSuccess, onErrors, reauthenticationCallback) => dispatch =>
//     backend.userService.signUp(user,
//         authenticatedUser => {
//             dispatch(signUpCompleted(authenticatedUser));
//             onSuccess();
//         },
//         onErrors,
//         reauthenticationCallback);

const loginCompleted = authenticatedUser => ({
    type: actionTypes.LOGIN_COMPLETED,
    authenticatedUser
})

export const loginWithGoogle = (onSuccess, onErrors) => dispatch => 
    firebase.loginWithGoogle(
        authenticatedUser => {
            dispatch(loginCompleted(authenticatedUser))
            onSuccess()
        },
        onErrors
    )

export const loginWithGithub = (onSuccess, onErrors) => dispatch => 
    firebase.loginWithGithub(
        authenticatedUser => {
            dispatch(loginCompleted(authenticatedUser))
            onSuccess()
        },
        onErrors
    )

    
export const loginFromPersistance = (onSuccess, onErrors) => dispatch => 
    firebase.loginFromPersistance(
    authenticatedUser => {
        dispatch(loginCompleted(authenticatedUser))
        onSuccess()
    },
    onErrors
)

export const logout = () => {
    firebase.logout()
    return ({type: actionTypes.LOGOUT})
};

const getNotesResult = notes => ({
    type: actionTypes.GET_NOTES_RESULT,
    notes
})

export const getNotes = () => dispatch => 
    firebase.getNotes(
        notes => {
            dispatch(getNotesResult(notes))
        },
        (err) => console.error(err)
    )

export const selectNote = (note) => ({type: actionTypes.SELECT_NOTE, note})

export const exitNote = () => ({type: actionTypes.EXIT_NOTE})

export const saveNote = (note, onSuccess, onErrors) => dispatch =>
    firebase.updateNote(note, () => {
        dispatch(getNotes())
        onSuccess()
    }, onErrors)

export const newNote = () => dispatch => {
    const now = new Date()
    const note = {
        title: 'New Note',
        body: '',
        creationDate: now,
        lastUpdate: now
    }
    firebase.newNote(note, () => dispatch(getNotes()), () => {})
}

export const removeNote = (id) => dispatch => {
    firebase.removeNote(
        id,
        () => {
            dispatch(getNotes())
            dispatch(selectNote(null))
        },
        () => {}
    )
}

export const changeFilter = (filter) => ({type: actionTypes.CHANGE_FILTER, filter})
export const changeSorting = (sorting) => ({type: actionTypes.CHANGE_SORTING, sorting})